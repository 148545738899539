/* @font-face {
  font-family: "Hero";
  font-style: "normal";
  font-weight: 400;
  src: url("./Hero-Regular.ttf");
}

@font-face {
  font-family: "Hero";
  font-style: "light";
  font-weight: 300;
  src: url("./Hero-Light.ttf");
}

@font-face {
  font-family: "Hero";
  font-style: "bold";
  font-weight: 600;
  src: url("./Hero-Bold.ttf");
} */

@font-face {
  font-family: "Commissioner";
  font-style: "regular";
  font-weight: 400;
  src: url("./Commissioner-Regular.ttf");
}
