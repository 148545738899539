.reviews {
  background-color: transparent;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.reviews__title {
  font-size: 2rem;
  margin-bottom: 60px;
}

.reviews__map {
  max-width: 560px;
  width: 100%;
  height: 800px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 700px) {
  .reviews {
    padding-top: 80px;
  }
}

@media (max-width: 600px) {
  .reviews {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
}

@media (max-width: 550px) {
  .reviews__title {
    font-size: 1.8rem;
  }
}
