.info {
  background-color: transparent;
  color: #fff;
  padding-top: 60px;
  display: flex;
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
}

.info__titles {
  position: absolute;
  bottom: 35%;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info__title {
  font-size: 62px;
  margin: 0;
  text-align: center;
  z-index: 1;
  padding: 0 5px;
  box-sizing: border-box;
}

.info__subtitle {
  font-size: 22px;
  letter-spacing: 2px;
  margin: 0;
  padding: 10px 5px 0 5px;
  z-index: 1;
  text-align: center;
  box-sizing: border-box;
}

.info__video {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  object-fit: cover;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.info__video_fade {
  opacity: 0;
}

.pause-button {
  position: absolute;
  left: 5%;
  bottom: 10%;
  width: 50px;
  height: 50px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  z-index: 1;
  padding: 0;
}

.pause-icon {
  width: 100%;
  height: 100%;
  fill: white;
  stroke: white;
  stroke-width: 5;
}

.pause-icon circle {
  fill: none;
  stroke-width: 8;
  transition: stroke-dasharray 0.25s;
}

.pause-icon rect,
.pause-icon polygon {
  fill: white;
}
.progress-bg {
  stroke: rgb(99, 99, 99);
}

.progress-bar {
  stroke: white;
  transition: stroke-dasharray 0.25s;
}

@media (max-width: 957px) {
  .info__title {
    font-size: 48px;
  }
  .info__subtitle {
    font-size: 18px;
  }
}

@media (max-width: 680px) {
  .info__title {
    font-size: 35px;
  }
  .info__subtitle {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .info__title {
    max-width: 450px;
  }
  .info__subtitle {
    max-width: 400px;
  }
}

@media (max-width: 418px) {
  .info__title {
    max-width: 350px;
  }
  .info__subtitle {
    max-width: 400px;
  }
}
