.equipment {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  background-color: transparent;
  padding-top: 100px;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.equipment__title {
  font-size: 2rem;
  margin-bottom: 60px;
}

.equipment__container {
  display: flex;

  width: 50vw;
  padding: 70px 0 70px 0;
  box-sizing: border-box;
}

.equipment__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  max-width: 768px;
}
.equipment__item {
  display: flex;
  flex-direction: column;
  border-radius: 50%;
}

.equipment__info {
  width: 284px;
  height: 100px;
  background-color: #4e4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.equipment__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.neumann {
  width: 640px;
  height: 70vh;
}

@media (max-width: 1600px) {
  .equipment__list {
    max-width: 640px;
  }
}

@media (max-width: 1345px) {
  .equipment__list {
    max-width: 512px;
  }
}

@media (max-width: 1190px) {
  .equipment__list {
    max-width: 440px;
  }
}

@media (max-width: 1118px) {
  .equipment__list {
    max-width: 340px;
  }
  .neumann {
    width: 600px;
  }
}

@media (max-width: 980px) {
  .neumann {
    width: 500px;
  }
}

@media (max-width: 955px) {
  .equipment__wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .neumann {
    width: 100%;
    height: 40vh;
  }
  .equipment__container {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }
  .equipment__list {
    max-width: 768px;
  }
}

@media (max-width: 865px) {
  .equipment__list {
    max-width: 600px;
  }
}

@media (max-width: 715px) {
  .neumann {
    height: 40vh;
  }
  .equipment {
    padding-top: 80px;
    padding-bottom: 20px;
  }
}

@media (max-width: 660px) {
  .equipment__list {
    max-width: 512px;
  }
}

@media (max-width: 575px) {
  .equipment__list {
    max-width: 460px;
  }
}

@media (max-width: 575px) {
  .equipment__title {
    font-size: 1.8rem;
  }
}

@media (max-width: 512px) {
  .equipment__list {
    max-width: 400px;
  }
}

@media (max-width: 444px) {
  .equipment__list {
    max-width: 320px;
  }
}
