.contacts {
  background-color: transparent;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.contacts__wrapper {
  display: flex;
  gap: 60px;
  justify-content: center;
  align-items: center;
}

.contacts__title {
  font-size: 2rem;
  margin-bottom: 60px;
}

.contacts__info {
  max-width: 512px;
}

.contacts__item {
  text-align: left;
  margin: 0;
  font-style: "light";
  font-weight: 300;
}

.contacts__paragraph {
  text-align: start;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 30px;
  max-width: 512px;
}

.contacts__list {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

.contacts__link {
  transition: opacity 0.3s ease-in-out;
}
.contacts__icon {
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.contacts__link:hover {
  opacity: 0.5;
}

.contacts__map {
  border-radius: 5px;
  width: 512px;
  height: 360px;
}

@media (max-width: 1144px) {
  .contacts__map {
    width: 400px;
  }
}

@media (max-width: 1014px) {
  .contacts__map {
    width: 350px;
  }
  .contacts__info {
    max-width: 380px;
  }
}

@media (max-width: 845px) {
  .contacts__map {
    width: 300px;
  }
}

@media (max-width: 790px) {
  .contacts__info {
    max-width: 300px;
  }
}

@media (max-width: 692px) {
  .contacts__wrapper {
    flex-direction: column;
  }

  .contacts__map {
    width: 512px;
  }
  .contacts {
    padding-top: 80px;
  }
}

@media (max-width: 564px) {
  .contacts__map {
    width: 380px;
  }
}

@media (max-width: 550px) {
  .contacts__title {
    font-size: 1.8rem;
  }
}

@media (max-width: 424px) {
  .contacts__map {
    width: 320px;
    height: 280px;
  }
}

@media (max-width: 360px) {
  .contacts__map {
    width: 280px;
  }
}
