@import url("./vendor/fonts.css");
body {
  /* max-width: 1536px; */
  margin: 0 auto;
  background-color: #000000;
  /* background-image: radial-gradient(
    ellipse at center,
    rgb(0, 0, 60) -100%,
    black 70%
  ); */
  font-family: "Commissioner";
  font-weight: 400;
  font-style: normal;
}
.scroll-section-outer {
  overflow: hidden;
}

.scroll-section-inner {
  height: 100vh;
  width: 250vw;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

.background {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: -1;
  filter: grayscale(100%) blur(8px);
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(0, 0, 0, 1) 100%
  );
  z-index: 1;
  pointer-events: none;
}
