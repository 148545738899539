.header {
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 3;
  margin: 0 auto;
  color: #fcfdff;
  padding: 30px 30px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-sizing: border-box;
}
.header__container {
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 3;
  margin: 0 auto;
  color: #fcfdff;
  padding: 30px 30px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-sizing: border-box;
  max-width: 1280px;
}

.header_colored {
  background-color: rgba(0, 0, 0, 0.7);
}

.header_colored::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  backdrop-filter: blur(5px);
}

.header__logo-wrapper {
  background-color: rgba(0, 0, 0, 0.85);
  width: 200px;
  height: 60px;
  position: fixed;
  left: 0;
  right: 0;
  top: 100px;
  margin: 0 auto;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  box-sizing: border-box;
  z-index: 3;
}

.header__logo {
  background-image: url(../../images/logo2.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 110px;
  width: 110px;
  height: 110px;
  cursor: pointer;
}

.header__adress {
  margin: 2px;
  padding: 0;
  font-size: 10px;
  text-align: right;
}

.header__list {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  gap: 30px;
  list-style: none;
}

.header__list_open {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  align-items: center;
  gap: 30px;
  list-style: none;
  padding: 20%;
}

.header__contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header__item {
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.header__item:hover {
  color: #afff05;
}

.header__phone {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
  color: inherit;
}

.header__menu {
  background-image: url(../../images/menu.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  height: 30px;
  width: 30px;
  display: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.header__menu_open {
  background-image: url(../../images/close.png);
  filter: invert(100%);
}

.header__wrapper-menu {
  display: flex;
  gap: 30px;
  align-items: center;
}

.header__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: black;
  overflow: hidden;
}

.header__wrapper_open {
  animation: slideDown 0.3s forwards;
}

.header__wrapper_close {
  animation: slideUp 0.3s forwards;
}

@media (min-width: 958px) {
  .header__wrapper {
    position: static;
    height: auto;
    width: auto;
    background-color: transparent;
    overflow: visible;
    animation: none;
    transform: none;
  }

  .header__wrapper_open,
  .header__wrapper_close {
    animation: none;
  }
}

@media (max-width: 1111px) {
  .header__item {
    font-size: 11px;
  }
  .header__list {
    gap: 18px;
  }
}

@media (max-width: 1006px) {
  .header__item {
    font-size: 10px;
  }
  .header__list {
    gap: 15px;
  }
}

@media (max-width: 957px) {
  .header__list {
    display: none;
  }
  .header__menu {
    display: flex;
  }
  .header__list_open {
    display: flex;
  }
  .header__item {
    font-size: 24px;
  }
  .header__item:hover {
    color: #000;
  }
}

@media (max-width: 540px) {
  .header__phone {
    font-size: 16px;
  }
  .header__adress {
    font-size: 8px;
  }
  .header__wrapper-menu {
    gap: 20px;
  }
}

@media (max-width: 350px) {
  .header__phone {
    font-size: 12px;
  }
  .header__wrapper-menu {
    gap: 10px;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
