.chat {
  position: fixed;
  right: 5%;
  bottom: 10%;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 6;
}
.chat_open {
  display: none;
}

.chat__menu {
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  right: 0;
  bottom: 50px;
  row-gap: 20px;
  min-height: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.chat__menu_open {
  height: 300px;
  opacity: 1;
}

.chat__icon {
  width: 50px;
  height: 50px;
  background-color: transparent;
  animation: pulse2 2.5s infinite;
  /* filter: invert(100%); */
  transition: all 0.4s ease-in-out;
}

.chat__icon_open {
  animation: none;
  transition: all 0.4s ease-in-out;
}

.chat__title {
  color: white;
}

.chat__animated-border {
  position: absolute;
  right: -20%;
  top: -20%;
  width: 70px;
  height: 70px;
  animation: pulseWithOpacity 2.5s infinite;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  box-shadow: 0 0 30px 10px #afff05;
}

.chat__animated-border_open {
  display: none;
}

@media (max-width: 380px) {
  .chat__icon {
    animation: none;
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1);
  }
  85% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulseWithOpacity {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  90% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
